// import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import MainServices from './Components/MainServices';
import OurTeam from './Components/OurTeam';
import AnandS from './Components/team/AnandS';
import SachinB from './Components/team/SachinB';
import RajatK from './Components/team/RajatK';
import Plans from './Components/Plans';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Servicelogined from './Components/Servicelogined';
// import SimpleForm from './Components/SimpleForm';
import Gallery from './Components/Gallery';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<MainServices />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<SignUp />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/Servicelogined' element={<Servicelogined />} />
        <Route path='/OurTeam' element={<OurTeam />} />
        <Route path='/Plans' element={<Plans />} />
        <Route path='/team/AnandS' element={<AnandS />} />
        <Route path='/team/SachinB' element={<SachinB />} />
        <Route path='/team/RajatK' element={<RajatK />} />
      </Routes>
      <Footer />
      {/* <SimpleForm/> */}
      </BrowserRouter>
    </div>
  );
  
}

export default App;
