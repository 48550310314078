import React from "react";
import { NavLink } from "react-router-dom";

export default function OurTeam() {
  return (
    <div>
      <section className="section-team">
        <div className="container">
          {/* Header Section */}
          <div className="row justify-content-center text-center">
            <div className="col-md-10 col-lg-8">
              <div className="header-section">
                <h2 className="display-6 text-center mb-4">                  
                </h2>
                <div class="header-section">                
                <h2 className="display-6 text-center mb-4">Meet <b>Our</b> Team </h2>            
                </div>
                <p className="lead text-center">
                  Our team consists of skilled professionals dedicated to delivering exceptional results.
                </p>
              </div>
            </div>
          </div>
          
          {/* Team Members */}
          <div className="row">
            {/* Team Member Card */}
            <div className="col-md-3 col-lg-3 mb3">
              <div className="team-member-card">
                <img src="/assets/team/AnandS.jpg" alt="John Doe" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Anand Samudra</h3>
                  <p className="role">Founder & Managing Director</p>
                  <p className="description">
                    Anand is a visionary leader with over 15 years of experience in the IT industry, guiding our company to success.<br />
                    Strategic Technology Visionary Driving Digital Transformation, Fostering Innovation, and Propelling Technological Excellence in Organization.
                  </p>
                  <NavLink to="../team/AnandS" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>
            
            <div className="col-md-3 col-lg-3 mb-3">
              <div className="team-member-card">
                <img src="/assets/team/SachinB.jpg" alt="Jane Smith" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Sachin Bhandwalkar</h3>
                  <p className="role">Chief Cyber Security Officer (CCSO)</p>
                  <p className="description">Cyber Security Expert Consultant & Certified Trainer - Mr. Sachin Bhandwalkar is a highly respected cybersecurity expert with over a decade of experience in the field. As a certified trainer and consultant,Mr. Bhandwalkar has made significant contributions to cybersecurity</p>
                  <NavLink to="/team/SachinB" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 mb-3">
              <div className="team-member-card">
                <img src="/assets/team/RajatK.jpg" alt="Rajat K" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Rajat Kumar</h3>
                  <p className="role">Cyber Security Expert Consultant </p>
                  <p className="description"> Rajat has strong focus on enhancing security through comprehensive vulnerability assessments and penetration testing. With a deep commitment to staying current with cybersecurity trends and advancements, Rajat actively engaged in ethical hacking and security research.</p>
                  <NavLink to="/team/RajatK" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 mb3">
              <div className="team-member-card">
                <img src="/assets/team/bytlas.jpg" alt="John" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Power BI And Advance MS-Excel Trainer</h3>
                  <p className="role">Trainer with 15+ Years experaice with MS-excel Spread sheet with VBA Macros </p>
                  <p className="description">
                    Microsoft Power BI |
                    Advance Excel |
                    VBA Macros |
                    Excel Automation |
                    Dashboard Creation |
                    Data Analysis |
                    Database managment |
                    Managment Information System
                  </p>
                  <NavLink to="../team/AnandS" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 mb3">
              <div className="team-member-card">
                <img src="/assets/team/bytlas.jpg" alt="John Doe" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Senior Training & Development Consultant</h3>
                  <p className="role">Seasoned professional committed to fostering organizational excellence through training and development.
                  </p>
                  <p className="description">
                  Conducts training on Performance Management, Conflict Management, 
                  Consultative Selling, Emotional Intelligence, Stress Management, 
                  Time Management, and Ethical Leadership.
                  </p>
                  <NavLink to="../team/AnandS" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>            

            <div className="col-md-3 col-lg-3 mb3">
              <div className="team-member-card">
                <img src="/assets/team/bytlas.jpg" alt="John Doe" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Soft Skill Trainer</h3>
                  <p className="role">Soft Skill Trainer</p>
                  <p className="description">

                  </p>
                  <NavLink to="../team/AnandS" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
