import React from "react";

export default function Services() { 
  return (
    <div>
      <section id="services">
        <div className="container my-2 py-3">
          <div className="row">
            <div className="col-12">
              <h2 className="display-6 text-center mb-4">Our <b>Awesome</b> Services </h2>
              <hr className='w-25 mx-auto' />
            </div>
          </div>
        </div>
        <div className="container d-flex align-items-center justify-content-center flex-wrap mb-5">
          {[
            { icon: "fa-laptop", title: "Web Design", description: "Web Design service is a comprehensive service that covers the design development and maintenance of websites It includes creating a website from scratch editing and updating existing websites providing graphic and animation design." },
            { icon: "fa-briefcase", title: "Business Intelligence", description: "Business Intelligence (BI) as a service is a cloud-based solution that enables companies to make better and more informed decisions by leveraging data-driven insights It provides an end-to-end solution that helps organizations access analyze." },
            { icon: "fa-database", title: "Database Management", description: "Database Management as a service is a cloud-based system that allows users to store and manage data in a secure reliable and cost-effective way It provides users with an easy-to-use platform for designing and managing databasesy." },
            { icon: "fa-solid fa-award", title: "Corporate Training Solutions - Customized Corporate Training", description: "Corporate Training Solutions - Customized Corporate Training" },
            { icon: "fa-mobile", title: "App Design", description: "Mobile App Design is a service that helps businesses create and develop mobile applications that can be used on iOS and Android devices It includes analysis UX/UI design coding and testing of mobile apps that meet the client’s needs and goals." },
            { icon: "fa-robot", title: "RPA Automation", description: "Robotic Process Automation (RPA) automation as a service is a cloud-based technology solution that allows businesses to automate routine manual tasks It uses artificial intelligence and machine learning algorithms to mimic the behavior of human users." },
            { icon: "fa-magnifying-glass-chart", title: "Data Analytics", description: "Data Analytics as a Service (DAaaS) is a type of cloud computing service that provides an organization with a comprehensive suite of analytics tools services and platforms." },
            { icon: "fa-building", title: "Reality", description: "Real estate property sales is a service that provides individuals and organizations with the resources they need to buy sell and manage real estate properties This includes helping clients identify potential properties to purchase negotiating sales contracts." }
          ].map((service, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index} data-aos="zoom-in" data-aos-duration="500" data-aos-delay={index % 4 * 200} data-aos-easing="ease-in-quad">
              <div className="box1">
                <div className="body1">
                  <div className="imgContainer d-flex bg-white flex-column justify-content-center border border align-items-center">
                    <i className={`fa ${service.icon} fa-4x mb-4 text-center`}></i><br />
                    <span className="cardtitle fw-bolder">{service.title.split(' ')[0]}</span>
                    <span className="cardtitle fw-bolder">{service.title.split(' ')[1]}</span>
                  </div>
                  <div className="content d-flex flex-column align-items-center justify-content-center">
                    <div className="footer1">
                      <h3 className="text-white fs-5">{service.title}</h3>
                      <p className="text-white">{service.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
