import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "./Header";
import swal from "sweetalert";


export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigation = useNavigate();

  async function login() {
    console.log("data", email, password);
    let item = { email, password };
    let result = await fetch("http://127.0.0.1:8000/api/login",
     {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
      });
      swal({
      // text: "something went wrong",
      text: "Login successfully",
      
    });
    
    result = await result.json();
    localStorage.setItem("user-info", JSON.stringify(result));
    navigation("/dashboard");
  }
 
  return (
    <>
      <div>
        <div className="container shadow my-5">
          <div   style={{"margin-top": "4rem"}} className="row">
            <div className="col-md-5 d-flex flex-column align-items-center text-white justify-content-center form">
              <h1 className="display-4 fw-bolder">Welcome Back</h1>
              <p className="lead text-center">
                Enter Your Credientials to Login
              </p>
              <h5 className="mb-4">OR</h5>
              <NavLink
                to="/register"
                className="btn btn-outline-light rounded-pill pb-2 w-50"
              >
                Sign In
              </NavLink>
            </div>
            <div className="col-md-6 p-5">
              <h1 className="display-6 fw-bolder mb-5">LOGIN</h1>
              <div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Remember me
                  </label>
                </div>
                <button
                  type="submit"
                  onClick={login}
                  className="btn btn-primary w-100 mt-4 rounded-pill"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
