import React from "react";

export default function AnandS() {
  return (
    <div className="container">
      <section className="section-profile">
        <div className="header-section">
        <h2 className="display-6 text-center mb-4">---------------</h2>
          <h2 className="display-6 text-center mb-4">Sachin Bhandwalkar</h2>
          <p className="lead text-center">Chief Cyber Security Officer (CCSO)</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="profile-card">
              <img
                src="/assets/team/SachinB.jpg"
                alt="Anand Samudra"
                className="profile-photo"
              />
              <div className="profile-body">
                <h3>Professional Information</h3>
                <p>
                Mr. Sachin Bhandwalkar is a highly respected cybersecurity expert with over a decade of experience in the field. As a certified trainer and consultant, Mr. Bhandwalkar has made significant contributions to cybersecurity across various sectors, with a special focus on working with Indian government bodies. His expertise lies in cyber security awareness and investigation, where he plays a pivotal role in assisting the government in cracking complex cybercrime cases. Known for his deep knowledge of cybersecurity practices, he is a trusted advisor and problem-solver in the domain of cyber threats, security protocols, and incident response.
                </p>
                <h3>Technical Proficiency</h3>
                <p>
                Experience: Over 10 years of professional experience in cybersecurity, with a focus on consulting and training.
                Current Role: Collaborates with Indian government agencies to enhance cyber security awareness programs and assist in solving cybercrime cases.
                Certifications: Recognized as a certified cybersecurity trainer, imparting knowledge and training to various stakeholders.
                Expertise: In-depth knowledge of cyber threat analysis, incident response, data protection, network security, and digital forensic investigations.
                </p>
                <h3>Quantitative Skills</h3>
                <p>
                Successfully conducted numerous cybersecurity awareness workshops for Indian government institutions.
                Actively involved in solving major cyber crime cases through advanced investigative techniques.
                Provided consultancy services to improve and implement cybersecurity protocols within government bodies.

                </p>
                <h3>Expertise</h3>
                <ul>
                  <li>Cyber Crime Investigation </li>
                  <li>Cyber Security Awareness Training</li>
                  <li>Technology Strategy & Infrastructure</li>
                  <li>Incident Response and Digital Forensics</li>
                  <li>Advisory for Government Cybersecurity Policies</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
