import React from "react";

export default function AnandS() {
  return (
    <div className="container">
      <section className="section-profile">
        <div className="header-section">
        <h2 className="display-6 text-center mb-4">---------------</h2>
          <h2 className="display-6 text-center mb-4">Anand Samudra</h2>
          <p className="lead text-center">Founder & Managing Director</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="profile-card">
              <img
                src="/assets/team/AnandS.jpg"
                alt="Anand Samudra"
                className="profile-photo"
              />
              <div className="profile-body">
                <h3>Professional Information</h3>
                <p>
                A seasoned professional with over 15+ years of supervisory expertise in efficiently managing BPO Voice and Non-Voice MIS across diverse domains, including Banking, Insurance, Telecom, Realty, Media, and other business segments. Possess a Six Sigma Green Belt certification, showcasing a commitment to operational excellence. Adept MIS function expert with a proven track record in end-to-end solution building, contributing significantly to business intelligence. Known for introducing innovative ideas, facilitating effective change management, and cultivating a reputation for problem resolution, ultimately enhancing customer satisfaction. Actively seeking a full-time position that presents professional challenges, leveraging strong interpersonal skills, exemplary time management, and advanced problem-solving abilities
                </p>
                <h3>Technical Proficiency</h3>
                <p>
                Proficient in the fundamental aspects of on-premises server management as well as cloud platforms such as AWS and Azure, with a comprehensive understanding of both Windows and Linux environments. Demonstrated expertise in database management and analysis, encompassing MS-Access and MS SQL / My SQL Proven track record in designing and formatting reports, creating visually compelling charts, and other graphic presentations. Skilled in the integration of databases, pivot tables, and graphs, with the ability to link data seamlessly. Experienced in publishing reports to web pages for wider accessibility. Adept at implementing automated processes using VBA (Macros) to ensure the accuracy of data maintenance and the swift generation of precise reports. Excels in leveraging these skills to enhance overall efficiency in data management and reporting processes. 
                </p>
                <h3>Quantitative Skills</h3>
                <p>
                  Advanced multitasking abilities, managing operational efficiency, data insights, and strategic decision-making. Known for end-to-end solution building, introducing innovative ideas, and facilitating effective change management.
                </p>
                <h3>Expertise</h3>
                <ul>
                  <li>Budgeting & Resource Management</li>
                  <li>Product Development</li>
                  <li>Technology Strategy & Infrastructure</li>
                  <li>Business Intelligence & Data Analysis</li>
                  <li>Microsoft Power BI & Tableau Business Intelligence tools </li>
                  <li>VBA, Visual Studio, API Integration</li>
                  <li>Laravel, Java, Bootstrap, React JS</li>
                  <li>Cloud: AWS, Azure, Windows, Linux</li>
                  <li>RPA (Robotic Process Automation)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
