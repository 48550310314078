import React from "react";
// import { NavLink } from "react-router-dom";

export default function Services() { 
  return (
    <div>
      <section id="services">
        <div className="container my-2 py-3">
          <div className="row">
            <div className="col-12">
              <h2 className="display-6 text-center mb-3">Our <b>Service</b> Plans </h2>
              <hr className='w-25 mx-auto' />
            </div>
          </div>
        </div>
        <div className="container d-flex align-items-center justify-content-center flex-wrap mb-5">
          {[
            {
              icon: "fa-laptop",
              title: "Basic Training Plan",
              description: [
                <li>Duration - 240 Minutes 4 Hours (One half-day session)</li>,
                <li>Introduction to Cybersecurity Fundamentals</li>,
                <li>Overview of Common Cyber Threats (phishing, malware, social engineering)</li>,
                <li>Safe Online Practices & Basic Password Hygiene</li>,
                <li>Hands-on Training on Cybersecurity Tools</li>,
                <li>Audience - Teams in key departments (Finance, HR, IT, Corporate, Business Support Function)</li>
              ],
            },

            {
              icon: "fa-briefcase",
              title: "Advanced Training Plan",
              description: [
                <li>Duration - 480 Minutes (8 Hours Full-day session)</li>,
                <li>All Basic Plan Topics, ++ </li>,
                <li>In-depth Analysis of Threat Landscape and Case Studies</li>,
                <li>Data Protection & Compliance (GDPR (General Data Protection Regulation) overview)</li>,
                <li>Hands-on Activities: Role-Play of Cybersecurity Scenarios</li>,
                <li>Audience - Teams in key departments (Finance, HR, IT, Corporate, Business Support Function)</li>
              ],
            },


            {
              icon: "fa-database",
              title: "Premium Training Plan",
              description: [
                <li>Duration - Multi-day Session  (16-24 Hours spread across 2-3 days)</li>,
                <li>All Advanced Plan Topics, ++ </li>,
                <li>Detailed Incident Response and Crisis Management</li>,
                <li>Penetration Testing & Vulnerability Assessment Training</li>,
                <li>Building a Cyber-Resilient Organization</li>,
                <li>Hands-on: Simulated Attack & Defense Exercise, Risk Assessment</li>,
                <li>Audience - Teams in key departments (Finance, HR, IT, Corporate, Business Support Function)</li>
              ],
            },


            {
              icon: "fa-solid fa-award",
              title: "Enterprise Training Plan",
              description: [
                <li>Duration - Custimized Durations</li>,
                <li> Customized Corporate Training Programs</li>,
                <li> For More Details Talk to Our Team</li>,

              ],
            },

          ].map((service, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index} data-aos="zoom-in" data-aos-duration="500" data-aos-delay={index % 4 * 200} data-aos-easing="ease-in-quad">
              <div className="box1">
                <div className="body1">
                  <div className="imgContainer d-flex bg-white flex-column justify-content-center border border align-items-center">
                    <i className={`fa ${service.icon} fa-4x mb-4 text-center`}></i><br />
                    <span className="cardtitle fw-bolder">{service.title.split(' ')[0]}</span>
                    <span className="cardtitle fw-bolder">{service.title.split(' ')[1]}</span>
                  </div>
                  <div className="content d-flex flex-column align-items-center justify-content-center">
                    <div className="footer1">
                      <h3 className="text-white fs-5">{service.title}</h3>
                      <p className="text-white">{service.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}