import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Services from './Services';
import About from './About';


export default function Home() {
  return (
    <div>
      <Carousel>
        <Carousel.Item  style={{"margin-top": "5rem"}} className='carousalheight'>
          <video
            className="d-block w-100"
            src="/assets/vdos/3.mp4"
            alt="First slide"
            autoPlay
            loop
            muted
          />
          <Carousel.Caption className='caption' >
            <h3 className='font '>Expert Solution <span className='span' >Digitalization</span> </h3>
            <p className='paragraph'>Professional Consulting from Functional Expertise for Software
              Solution Digitalization,</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item  style={{"margin-top": "5rem"}} className='carousalheight'>
          <video
            className="d-block w-100"
            src="/assets/vdos/1 (7).mp4"
            alt="Second slide"
            autoPlay
            loop
            muted
          />

          <Carousel.Caption >
            <h3 className='font'>Project <span className='span'>Excellence</span></h3>         
            <p className='paragraph'>We create software that brings harmony to every project and makes
              human cooperation easier.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item  style={{"margin-top": "5rem"}} className='carousalheight'>
          <video
            className="d-block w-100"
            src="/assets/vdos/1 (8).mp4"
            alt="Third slide"
            autoPlay
            loop
            muted
          />

          <Carousel.Caption>
            <h3 className='font'>Team  <span className='span'>Adventures</span></h3>
            <p className='paragraph'> The team has experience in different Industry best practices,
              ensuring a valuable investment whole brain approach,</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item  style={{"margin-top": "5rem"}} className='carousalheight'>
          <video
            className="d-block w-100"
            src="/assets/vdos/2.mp4"
            alt="Fourt slide"
            autoPlay
            loop
            muted
          />
          <Carousel.Caption >
            <h3 className='font'>Bussiness <span className='span'>Real-Time Insights</span></h3>
            <p className='paragraph'>
              Initiate value adds to your business with Automation &amp; Analytics
              to create a data-driven culture with business intelligence that enables
              everyone 
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    
      <About />

      <Services />



    </div>

  )
}
