import React from "react";
import {NavLink} from "react-router-dom"

export default function MainServices() {
  return (
    <div>
      <section class="section-services">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-md-10 col-lg-8">
              <div class="header-section">
          
              <h2 className="display-6 text-center mb-4">Our <b>Awesome</b> Services </h2>
          
              </div>
            </div>
          </div>
          <div class="row1" >
            <div class="col-md-6 col-lg-4"  data-aos="zoom-in"  data-aos-duration="500" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-laptop"></i>
                  </span>
                  <h3 class="title">Web Design</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before "></span>
              </div>
            </div>

            <div class="col-md-6 col-lg-4"   data-aos="zoom-in"   data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-briefcase"></i>
                  </span>
                  <h3 class="title">Business Intelligence</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div>

            <div class="col-md-6 col-lg-4"   data-aos="zoom-in"   data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-database"></i>
                  </span>
                  <h3 class="title">Database Management</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div>

            {/* <div class="col-md-6 col-lg-4"  data-aos="zoom-in"  data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-sack-dollar"></i>
                  </span>
                  <h3 class="title">Financial Management</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div> */}

            <div class="col-md-6 col-lg-4" data-aos="zoom-in"  data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-mobile"></i>
                  </span>
                  <h3 class="title">Mobile App Design</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div>

            <div class="col-md-6 col-lg-4"  data-aos="zoom-in"   data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-robot"></i>
                  </span>
                  <h3 class="title">Robotic Process Automation</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div>
            <div class="col-md-6 col-lg-4"  data-aos="zoom-in"  data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    {/* <i class="fa fa-magnifying-glass-chart"></i>*/}

                    <i class="fa-solid fa-award"></i>
                                      </span>
                  <h3 class="title">Corporate Training Solutions - Customized Corporate Training</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div>
            
            {/* <div class="col-md-6 col-lg-4" data-aos="zoom-in"   data-aos-duration="700" data-aos-easing="ease-in-quad">
              <div class="single-service">
                <div class="content">
                  <span class="icon">
                    <i class="fa fa-building"></i>
                  </span>
                  <h3 class="title">Reality</h3>
                  <p class="description"> Bytlasintelligence-Digitalworld.</p>
                  <NavLink to="/Servicelogined" >
                            Learn More</NavLink>
                </div>
                <span class="circle-before"></span>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
