import React from "react";

export default function AnandS() {
  return (
    <div className="container">
      <section className="section-profile">
        <div className="header-section">
        <h2 className="display-6 text-center mb-4">---------------</h2>
          <h2 className="display-6 text-center mb-4">Rajat Kumar</h2>
          <p className="lead text-center"> Cyber Security Expert Consultant</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="profile-card">
              <img
                src="/assets/team/Rajatk.jpg"
                alt="Rajat Kumar"
                className="profile-photo"
              />
              <div className="profile-body">
                <h3>Professional Information</h3>
                <p>
                Mr. Rajat Kumar  is an aspiring Penetration Tester with a strong focus on enhancing security through comprehensive vulnerability assessments and penetration testing. With a deep commitment to staying current with cybersecurity trends and advancements.
                </p>
                <h3>Technical Proficiency</h3>
                <p>
                Ethical Hacker: Expert in identifying vulnerabilities and improving security postures through ethical hacking practices.

                Red Teamer: Adept at simulating real-world cyberattacks to test an organization's defense mechanisms and uncover security gaps.

                Penetration Tester: Proficient in assessing networks, applications, and systems for vulnerabilities and delivering actionable insights.

                Cyber Crime Volunteer (UP Police): Collaborates with law enforcement agencies to tackle cybercrime, aiding in investigations and strengthening cybersecurity initiatives.

                Cyber Security Instructor: Experienced in training students and professionals in cyber security principles, penetration testing, and best practices.

                Public Speaker: Engages in public speaking at conferences, webinars, and workshops on ethical hacking and cybersecurity.

                CTF Player (Hack the Box & TryHackMe): Actively participates in cybersecurity challenges to sharpen technical skills and remain updated with industry trends.

                </p>
                <h3>Quantitative Skills</h3>
                <p>
                
                Certified Ethical Hacker (CEH): Successfully certified as an Ethical Hacker, demonstrating a strong understanding of hacking methodologies and tools.

                Top Contributor on Hack the Box & TryHackMe: Earned a distinguished reputation by solving complex cybersecurity challenges and consistently ranking among the top players.

                Volunteer Contribution to UP Police Cyber Crime: Played a key role in assisting law enforcement in digital forensics and investigations, helping solve critical cases.

                Public Speaker at Cybersecurity Conferences: Invited as a speaker at multiple events, sharing insights on topics like penetration testing, vulnerability management, and ethical hacking.

                Trained Over 500 Students and Professionals: Delivered cybersecurity training sessions covering a range of topics including penetration testing, ethical hacking, and digital forensics.
                </p>
                <h3>Expertise</h3>
                <ul>
                  <li>Vulnerability Assessment & Penetration Testing (VAPT): Identifying and exploiting weaknesses in systems to provide actionable security improvements. </li>
                  <li>Red Team Operations: Conducting covert penetration tests and simulating advanced cyberattack scenarios.</li>
                  <li>Technology Strategy & Infrastructure</li>
                  <li>Web Application Security: Testing web applications for OWASP Top 10 vulnerabilities and beyond.</li>
                  <li>Incident Response: Guiding organizations through the aftermath of security breaches, focusing on containment and remediation.</li>
                  <li>Security Awareness Training: Conducting workshops and training sessions to build organizational resilience against cyber threats.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
